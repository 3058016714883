import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Link } from 'gatsby'
import yearMonthStyle from './yearMonth.module.scss'

const YearMonth = ({ year, month, count }) => {
  let monthContent = null
  if (month) {
    monthContent = (
      <>
        <span>/</span>
        <span>{month}</span>
      </>
    )
  }
  let date = `${year}`
  if (month) {
    date += `-${numeral(month).format('00')}`
  }

  return (
    <Link
      className={yearMonthStyle.yearMonthLink}
      to={`/blog/archives/${date}`}
    >
      <span>{year}</span>
      {monthContent}
      <span className={yearMonthStyle.count}>({count})</span>
    </Link>
  )
}

YearMonth.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number,
  count: PropTypes.number.isRequired,
}

YearMonth.defaultProps = {
  month: null,
}

export default YearMonth
