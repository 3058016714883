import React from 'react'
import PropTypes from 'prop-types'
import Year from './Year'
import ArchivesStyle from './archives.module.scss'

const Archives = ({ list }) => (
  <div>
    <p className={ArchivesStyle.archiveTitle}>月間アーカイブ</p>
    <ul>
      {list.map((item, index) => (
        <li key={index} className={ArchivesStyle.archiveListItem}>
          <Year yearMonthList={item} />
        </li>
      ))}
    </ul>
  </div>
)

Archives.propTypes = {
  list: PropTypes.array.isRequired,
}

export default Archives
