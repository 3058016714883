import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import HeaderStyle from './header.module.scss'
import Item from './Item'

export const HeaderItemType = {
  blog: 1,
  about: 2,
}

const Header = ({ activeType }) => {
  const [isActive, setIsActive] = useState(false)

  const fetchPathState = async () => {
    setIsActive(window.location.pathname === '/about')
  }

  useEffect(() => {
    fetchPathState()
  })

  return (
    <header className={HeaderStyle.headerRoot}>
      <Link className={HeaderStyle.headerTitle} to="/">
        MakitSystem
      </Link>
      <nav className={HeaderStyle.headerNav}>
        <ul>
          <li>
            <Item isActive={isActive} to="/about" name="About" />
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  activeType: PropTypes.number.isRequired,
}

export default Header
