import { graphql, useStaticQuery } from 'gatsby'

const TagsData = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query TagsData {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              frontmatter {
                tags
              }
            }
          }
        }
      }
    `
  )
  const tagCounts = {}
  allMarkdownRemark.edges.forEach(item => {
    item.node.frontmatter.tags.forEach(tag => {
      if (tagCounts.hasOwnProperty(tag)) {
        tagCounts[tag] = tagCounts[tag] + 1
      } else {
        tagCounts[tag] = 1
      }
    })
  })

  return Object.keys(tagCounts)
    .sort()
    .map(item => {
      return { name: item, count: tagCounts[item] }
    })
}

export default TagsData
