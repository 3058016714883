import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment-timezone'

const ArchivesData = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ArchivesData {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              frontmatter {
                date
              }
            }
          }
        }
      }
    `
  )
  const archiveCount = {}
  allMarkdownRemark.edges.forEach(item => {
    const yearMonth = moment
      .tz(item.node.frontmatter.date, 'UTC')
      .format('YYYY-MM')
    if (archiveCount.hasOwnProperty(yearMonth)) {
      archiveCount[yearMonth] = archiveCount[yearMonth] + 1
    } else {
      archiveCount[yearMonth] = 1
    }
  })

  const yearObj = {}
  Object.keys(archiveCount)
    .sort((a, b) => {
      return b - a
    })
    .forEach(item => {
      const yearMonthObj = moment(item)
      const year = yearMonthObj.year()
      const month = yearMonthObj.month() + 1
      const count = archiveCount[item]
      const obj = { year, month, count }
      if (yearObj.hasOwnProperty(String(year))) {
        yearObj[String(year)].push(obj)
      } else {
        yearObj[String(year)] = [obj]
      }
    })
  return Object.keys(yearObj)
    .sort((a, b) => {
      return b - a
    })
    .map(item => yearObj[item])
}

export default ArchivesData
