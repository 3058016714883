import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ItemStyle from './item.module.scss'
import classNames from 'classnames/bind'

const Item = ({ name, isActive, to }) => {
  const cx = classNames.bind(ItemStyle)
  const headerItemStyle = cx('headerItem', { headerItemActive: isActive })
  return (
    <Link className={headerItemStyle} to={to}>
      <p>{name}</p>
    </Link>
  )
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
}

export default Item
