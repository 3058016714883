import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import TagStyle from './tag.module.scss'

const Tag = ({ name, count }) => (
  <Link className={TagStyle.sideTagLink} to={`/blog/tags/${name}`}>
    <span>{name}</span>
    <span>({count})</span>
  </Link>
)

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
}

export default Tag
