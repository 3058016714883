import React from 'react'
import { Link } from 'gatsby'
import Style from './footer.module.scss'

const Footer = () => (
  <footer className={Style.footerRoot}>
    <p>Copyright © Makitsystem 2020.</p>
    <Link className={Style.footerLinkAbout} to="/about">
      About
    </Link>
    <Link className={Style.footerLinkTermsOfService} to="/termsOfService">
      利用規約
    </Link>
  </footer>
)

export default Footer
