import React from 'react'
import PropTypes from 'prop-types'
import Tag from './Tag'
import TagsStyle from './tags.module.scss'

const Tags = ({ items }) => {
  return (
    <aside>
      <p className={TagsStyle.tagTitle}>タグ</p>
      <ul className={TagsStyle.tagList}>
        {items.map(item => (
          <li key={item.name}>
            <Tag name={item.name} count={item.count} />
          </li>
        ))}
      </ul>
    </aside>
  )
}

Tags.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Tags
